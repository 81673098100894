<template>
  <UITextModal
    modal-name="moodBoardVideoUrlModal"
    max-width="560"
    @hideModal="clearUrl"
  >
    <ValidationObserver
      v-slot="{ handleSubmit }"
      ref="form"
      tag="div"
      class="pt-14 pb-8 px-6 px-sm-10"
    >
      <v-form @submit.prevent="handleSubmit(submitHandler)">
        <ValidationProvider v-slot="{ errors }" class="mb-sm-6" mode="eager" name="YouTube video link" rules="required|youtube_url" tag="div" vid="video_url">
          <div class="input-label font-weight-medium text_color_18--text mb-2">YouTube video link</div>
          <v-text-field
            v-model="url"
            dense
            outlined
            hide-details
            autofocus
            placeholder="Enter youtube video link"
            class="input rounded-0"
          ></v-text-field>
          <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown" mode="out-in">
            <div class="input-error text_color_5--text mt-1" v-if="errors.length">
              <b>Error!</b> {{ errors [0] }}
            </div>
          </transition>
        </ValidationProvider>

        <div class="text-right">
          <v-btn
            depressed
            width="180"
            type="submit"
            height="36"
            color="primary"
            class="save-template-btn font-weight-semi-bold rounded-0"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </ValidationObserver>

  </UITextModal>
</template>

<script>
import {mapGetters} from "vuex";
import UITextModal from "@/components/UI/UITextModal";

export default {
  name: "AddVideoModal",
  components: {
    UITextModal,
  },
  data() {
    return {
      loading: false,
      url: '',
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
    ]),
    file() {
      return this.getModal('moodBoardVideoUrlModal').data.file;
    }
  },
  watch: {
    file: {
      deep: true,
      handler(nv) {
        this.url = nv ? nv.file_source : '';
      }
    }
  },
  methods: {
    submitHandler() {
      if (this.file) {
        if (this.url === this.file.file_source) return;
        this.$emit('updateFile', [{...this.file, file_source: this.url}]);
      } else {
        this.$emit('createFile', [{type: 1, file_source: this.url}]);
      }

      this.$store.dispatch('closeModal', 'moodBoardVideoUrlModal');
      this.clearUrl();
    },
    clearUrl() {
      this.url = '';
      this.$refs.form.reset();
    }
  }
}
</script>
