<template>
  <div class="mood-board-page d-flex flex-column fill-height">
    <PageHeader>
      <div v-if="getProject.id" class="d-flex align-center justify-space-between space-x-4">
        <h1 class="d-flex align-center text-title-1 overflow-hidden">
          <UiBtn icon class="mr-1" color="gray-60 flex-shrink-0" @click="goToProjectDesign">
            <IconArrowLeft width="16" style="margin-top: 2px;"/>
          </UiBtn>
          <v-tooltip top color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <a @click="goToProjectDesign" class="text-truncate flex-grow-1" v-bind="attrs" v-on="on">{{getProject.title }}</a>
            </template>
            <div class="text-captions-1">
              {{ getProject.title }}
            </div>
          </v-tooltip>
          <IconDoubleChevronRight width="15" class="mx-4 flex-shrink-0"/>
          <span v-if="moodBoardData" class="text-no-wrap">{{ moodBoardData.name }}</span>
        </h1>
        <div class="flex-shrink-0">
          <UiBtn outlined link color="accent"
                 :to="{name: 'ProjectEdit', params: {project_id: this.$route.params.project_id}}">
            <IconSlidersV width="16" class="mr-2"/>
            Specification
          </UiBtn>
          <UiBtn color="accent" fab :disabled="getProject.id === null" class="ml-3 d-lg-none"
                 @click="$store.dispatch('toggleProjectSideDrawer', !showProjectSideDrawer)">
            <IconExpandRight v-if="showProjectSideDrawer" width="16"/>
            <IconExpandLeft v-else width="16"/>
          </UiBtn>
        </div>
      </div>

      <MoodBoardMenu
        :active-moodboard="activeMoodboard"
        :creatingMoodboard="creatingMoodboard"
        :filesHistory="filesHistory"
        :files="files"
        :moodBoardData="moodBoardData"
        :moodBoardList="moodBoardList"
        @changeMoodboard="onChangeMoodboard"
        @backStep="backStep"
        @forwardStep="forwardStep"
        @uploadFiles="uploadFiles"
        @buildGrid="buildGrid"
        @createBoard="createMoodboard"
        @lockUnlockBoard="lockUnlockMoodboard"
        @removeMoodBoard="removeMoodBoard"
        @renameMoodboard="renameMoodboard"
        @sortBoard="sortMoodboardList"
        @updateListMenu="moodBoardList = $event"
      />
      <SideDrawerControls class="d-none d-lg-flex"/>
    </PageHeader>

    <div class="mood-board relative overflow-hidden" ref="mood_board">
      <template v-if="!gridLoading">
        <vue-flex-waterfall col="4" col-spacing="24" :break-at="breakAt">
          <div
            v-for="item in files"
            :key="item.id"
            :style="{margin: '0 0 24px 0', width: `${getColumnWidth}px`, height: `${item.position.height}px`}"
            class="waterfall-item"
          >
          </div>
        </vue-flex-waterfall>
      </template>

      <v-progress-circular
        v-if="filesLoading"
        :size="100"
        color="accent"
        indeterminate
        class="mood-board-page-loader"
      />

      <div class="mood-board-files-wrap" ref="mood_board_files">
        <UIFilesUploader
          :files-formats="$config.project.modulesFileFormats[$config.project.modules.moodBoard]"
          :max-file-size="$config.filesystem.project.moodBoard.file.maxUploadFileSize"
          :disabled="!getPermission($route.params.project_id).moodboard['can-edit-moodboard'] || (moodBoardData && moodBoardData.disabled) || filesLoading"
          :input-file-select="false"
          tag="div"
          class="mood-board-files-wrap d-block"
          @onChange="uploadFiles"
        >
          <template v-if="!filesLoading">
            <VueDraggableResizable
              v-for="file in files"
              :key="file.id"
              :minWidth="100"
              :minHeight="100"
              :w="file.position.width"
              :h="file.position.height"
              :x="file.position.x"
              :y="file.position.y"
              :active="file.active && !moodBoardData.disabled && getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
              :draggable="!file.loading && !moodBoardData.disabled && getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
              :resizable="!file.loading && !moodBoardData.disabled && getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
              :parent="true"
              dragHandle=".file-drag-icon"
              :style="{'z-index': file.position.z_index}"
              class-name="mood-board-file d-flex align-center justify-center vdr"
              :onDragStart="updateFilesHistory"
              :onResizeStart="updateFilesHistory"
              @activated="onActivated(file)"
              @deactivated="file.active = false"
              @dragstop="onDragStop"
              @resizestop="onResizeStop"
            >
              <div class="d-flex align-center justify-center fill-width fill-height" @click="file.active = true">
                <div
                  v-if="!moodBoardData.disabled && getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
                  class="d-flex mood-board-file-menu"
                >
                  <div class="d-flex align-center justify-center file-drag-icon mr-auto rounded gray-30 gray-60--text">
                    <IconMove width="14"/>
                  </div>
                  <FileMenu :file="file" @duplicateFile="duplicateFile" @deleteFile="deleteFile"></FileMenu>
                </div>
                <div class="mood-board-file-content align-center justify-center fill-width fill-height" v-show="!file.loading">
                  <img v-if="file.file_type === FILE_TYPES.IMAGE" class="fill-height fill-width object-cover" :src="file.file.url" alt="">
                  <template v-if="file.file_type === FILE_TYPES.VIDEO">
                    <div v-if="!file.showVideo" class="mood-board-video-preview fill-width fill-height">
                      <img
                        :src="`https://img.youtube.com/vi/${getYouTubeVideoId(file.file_source)}/hqdefault.jpg`"
                        alt=""
                        class="fill-height fill-width object-cover"
                      >
                      <v-btn
                        width="68"
                        height="48"
                        class="mood-board-load-video-btn text-center"
                        @click="file.showVideo = true;"
                      >
                        <v-icon size="20" color="white">fa-play</v-icon>
                      </v-btn>
                    </div>
                    <iframe
                      v-if="file.showVideo"
                      :src="`https://www.youtube.com/embed/${getYouTubeVideoId(file.file_source)}?autoplay=1`"
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; autoplay;"
                      allowfullscreen
                    />
                  </template>
                  <audio v-if="file.file_type === FILE_TYPES.AUDIO" :src="file.file.url" class="mx-4 fill-width"
                         controls>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </div>
                <v-progress-circular
                  v-show="file.loading"
                  :size="40"
                  color="accent"
                  indeterminate
                />
              </div>
            </VueDraggableResizable>
          </template>
        </UIFilesUploader>
      </div>
    </div>

    <AddVideoModal
      v-if="getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
      @createFile="uploadFiles"
      @updateFile="updateFiles"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueFlexWaterfall from 'vue-flex-waterfall';
import VueDraggableResizable from 'vue-draggable-resizable'
import PageHeader from "@/components/layout/PageHeader";
import UiBtn from "@/components/UI/UiBtn";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import MoodBoardMenu from "@/views/project/moodboard/MoodBoardMenu";
import FileMenu from "@/views/project/moodboard/FileMenu";
import AddVideoModal from "@/views/project/moodboard/AddVideoModal";
import SideDrawerControls from "@/components/project/side-drawer/SideDrawerControls";

export default {
  name: "MoodBoardPage",
  components: {
    SideDrawerControls,
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconSlidersV: () => import('@/components/icons/IconSlidersV'),
    IconExpandRight: () => import('@/components/icons/IconExpandRight'),
    IconExpandLeft: () => import('@/components/icons/IconExpandLeft'),
    IconMove: () => import('@/components/icons/IconMove'),


    VueFlexWaterfall,
    VueDraggableResizable,
    PageHeader,
    UiBtn,
    UIFilesUploader,
    MoodBoardMenu,
    FileMenu,
    AddVideoModal,
  },
  data() {
    return {
      creatingMoodboard: false,
      activeMoodboard: -1,
      FILE_TYPES: {
        IMAGE: 0,
        VIDEO: 1,
        AUDIO: 2,
      },
      filesLoading: true,
      gridLoading: false,
      moodBoardList: [],
      moodBoardDefaultHeight: 0,
      menu: false,
      files: [],
      currentFile: null,
      filesHistory: {
        index: 0,
        steps: [],
        defaultStep: [],
      },
      breakAt: (() => {
        return {600: 2}
      })(),
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getProject',
      'showProjectSideDrawer'
    ]),
    moodBoardModule() {
      return this.getProject.modules.find(module => module.type === this.$config.project.moduleTypes.moodBoard)
    },
    maxFileIndex() {
      return this.files.length ? Math.max(...this.files.map(item => parseInt(item.position.z_index, 10))) : 0
    },
    getColumnWidth() {
      const columnCount = this.$vuetify.breakpoint.xsOnly ? 2 : 4;
      return Math.floor(document.body.clientWidth / columnCount - 18);
    },
    moodBoardData() {
      return this.moodBoardList.find(board => board.id === +this.activeMoodboard)
    }
  },
  async created() {
    const res = await this.$api.project.get(this.$route.params.project_id);
    await this.$store.dispatch('setProject', res.data);
  },
  async mounted() {
    const moodBoardData = await this.$api.moodBoard.info(this.$route.params.project_id);

    this.moodBoardList = moodBoardData.data.map(moodboard => {
      return this.transformMoodboardFromBackend(moodboard)
    })

    const moodboardId = this.$route.query.moodboard

    if(moodboardId && this.moodBoardList.findIndex(board => board.id === +moodboardId) === -1){
     return this.$router.push({name: 'NotFoundPage'})
    }

    await this.onChangeMoodboard(moodboardId || this.moodBoardList[0].id)
    await this.loadMoodboardFile()
  },
  methods: {
    async lockUnlockMoodboard(moodboard){
      moodboard.loading = true
      try{
        const board = await this.$api.moodBoard.update(this.$route.params.project_id, moodboard.id, {status: moodboard.status !== 6 ? 6: 1})
        this.moodBoardList = board.data.map(board => this.transformMoodboardFromBackend(board))

        // moodboard = Object.assign(moodboard, this.transformMoodboardFromBackend(board.data))
      } catch (e) {
        console.error(e)
      } finally {
        moodboard.loading = false
      }
    },

    async sortMoodboardList({moodboard, order}) {
      moodboard.loading = true
      try{
        const board = await this.$api.moodBoard.update(this.$route.params.project_id, moodboard.id, {order})
        this.moodBoardList = board.data.map(board => this.transformMoodboardFromBackend(board))
      } catch (e) {
        console.error(e)
      } finally {
        moodboard.loading = false
      }
    },
    async createMoodboard() {
      if(!this.moodBoardModule) return
      this.creatingMoodboard = true
      try{
        const board = await this.$api.moodBoard.create(this.$route.params.project_id, {
          name: `Whiteboard ${this.moodBoardList.length + 1}`,
          project_module_id: this.moodBoardModule.id
        })
        this.moodBoardList.push(this.transformMoodboardFromBackend(board.data))
        await this.onChangeMoodboard(board.data.id)
      } catch (e) {
        console.error(e)
      } finally {
        this.creatingMoodboard = false
      }
    },

    removeMoodBoard(moodBoard) {
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want to delete this whiteboard?',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            moodBoard.loading = true;
            this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.moodBoard.delete(this.$route.params.project_id, moodBoard.id);
              if(+this.activeMoodboard === +moodBoard.id) {
                await this.onChangeMoodboard(this.moodBoardList.find(board => board.is_default).id);
              }
              this.moodBoardList = this.moodBoardList.filter(board => +board.id !== +moodBoard.id);
            } catch (error) {
              console.error(error);
            } finally {
              moodBoard.loading = false;
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        },
      });
    },

    async renameMoodboard(moodboard) {
      moodboard.loading = true
      try{
        const board = await this.$api.moodBoard.update(this.$route.params.project_id, moodboard.id, {name: moodboard.newName})
        this.moodBoardList = board.data.map(board => this.transformMoodboardFromBackend(board))
      } catch (e) {
        console.error(e)
      } finally {
        moodboard.loading = false
      }
    },

    async loadMoodboardFile() {
      this.moodBoardDefaultHeight = this.$refs.mood_board_files.clientHeight; // $el maybe??

      const files = await this.$api.moodBoard.filesList(this.$route.params.project_id, this.moodBoardData.id);
      this.files = files.data.map(file => this.transformFileFromBackend(file));

      setTimeout(() => {
        const grid = document.querySelector('.vue-flex-waterfall');
        if (grid.clientHeight > this.$refs.mood_board_files.clientHeight) {
          this.$refs.mood_board_files.style.height = `${grid.clientHeight}px`;
        }
        this.filesLoading = false;
      }, 50);
    },
    async onChangeMoodboard(id) {
      if(+this.activeMoodboard === +id) return

      this.activeMoodboard = id
      await this.$router.$updateQueryParams({moodboard: id});

      this.filesLoading = true
      this.filesHistory = {
        index: 0,
        steps: [],
        defaultStep: [],
      }
      await this.loadMoodboardFile()
    },
    getYouTubeVideoId(url) {
      return url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)[1];
    },
    getFileType(file) {
      switch (file.type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif':
          return this.FILE_TYPES.IMAGE;
        case 1:
          return this.FILE_TYPES.VIDEO;
        case 'audio/mpeg':
          return this.FILE_TYPES.AUDIO;
      }
    },
    generateRandomPos() {
      const randomInt = () => Math.floor(Math.random() * (10 - 1 + 1)) + 1;
      const moodBoardWidth = this.$refs.mood_board.clientWidth;
      const fileMinW = 300; // 300px
      const fileMinH = this.moodBoardDefaultHeight > 500 ? 300 : 100; // initial file height will be 300px or 100px
      // we need to check if moodBoardHeight is less then 500px, then we must set smaller height to file,
      // cause if we set 300px then we will get more then 100% (300/250*100=120%), which is completely wrong
      return {
        x: 40 + randomInt(),
        y: 40 + randomInt(),
        width: parseInt(fileMinW / moodBoardWidth * 100, 10),
        height: parseInt(fileMinH / this.moodBoardDefaultHeight * 100, 10),
      }
    },
    onActivated(file) {
      this.currentFile = this.files.find(item => item.id === file.id);
      if (this.currentFile.position.z_index !== this.maxFileIndex) {
        this.currentFile.position.z_index = this.maxFileIndex + 1;
        this.updateFiles([this.currentFile]);
      }
    },
    onDragStop(x, y) {
      this.currentFile.position.x = x;
      this.currentFile.position.y = y;
      this.updateFiles([this.currentFile]);
    },
    onResizeStop(x, y, width, height) {
      this.currentFile.position = {
        ...this.currentFile.position, x, y, width, height
      };
      this.updateFiles([this.currentFile]);
    },
    uploadFiles(files) {
      this.updateFilesHistory();
      let formData = new FormData();

      files.forEach((file, idx) => {
        const fileType = this.getFileType(file);
        const {x, y, width, height} = this.generateRandomPos();

        if (fileType === 1) {
          formData.append(`files[${idx}][file_source]`, `${file.file_source}`);
        } else {
          formData.append(`files[${idx}][file]`, file);
        }

        formData.append(`files[${idx}][file_type]`, `${fileType}`);
        formData.append(`files[${idx}][description]`, "");
        formData.append(`files[${idx}][position][x]`, `${x}`);
        formData.append(`files[${idx}][position][y]`, `${y}`);
        formData.append(`files[${idx}][position][width]`, `${width}`);
        formData.append(`files[${idx}][position][height]`, `${height}`);
        formData.append(`files[${idx}][position][z-index]`, `${this.maxFileIndex + 1 + idx}`);
      });

      this.$api.moodBoard.createFiles(this.$route.params.project_id, this.moodBoardData.id, formData)
        .then(res => {
          this.files.push(...res.data.map(file => this.transformFileFromBackend(file)));
        })
        .catch(err => {
          console.error(err);
        });
    },
    updateFiles(files) {
      const moodBoard = this.$refs.mood_board;
      let formData = new FormData();

      files.forEach((file, idx) => {
        const width = file.position.width / moodBoard.clientWidth * 100; // convert from px to %
        const height = file.position.height / this.moodBoardDefaultHeight * 100; // convert from px to %
        const x = file.position.x / (moodBoard.clientWidth - file.position.width) * 100 || 0; // convert from px to %
        const y = file.position.y / this.moodBoardDefaultHeight * 100 || 0; // convert from px to %
        if (file.file_type === this.FILE_TYPES.VIDEO) {
          formData.append(`files[${idx}][file_source]`, `${file.file_source}`);
        }

        formData.append(`files[${idx}][file_id]`, file.id);
        formData.append(`files[${idx}][file_type]`, `${file.file_type}`);
        formData.append(`files[${idx}][description]`, "");
        formData.append(`files[${idx}][position][width]`, `${width}`);
        formData.append(`files[${idx}][position][height]`, `${height}`);
        formData.append(`files[${idx}][position][x]`, `${x}`);
        formData.append(`files[${idx}][position][y]`, `${y}`);
        formData.append(`files[${idx}][position][z-index]`, `${file.position.z_index}`);
      });

      if (files.length > 1) {
        this.files = this.files.map(file => ({...files.find(item => file.id === item.id), loading: true}));
      } else {
        this.files = this.files.map(file => file.id === files[0].id ? {...files[0], loading: true} : file);
      }

      this.$api.moodBoard.updateFiles(this.$route.params.project_id, this.moodBoardData.id, formData)
        .then(res => {
          if (files.length > 1) {
            this.files = this.files.map(file => ({...files.find(item => file.id === item.id), loading: false}));
          } else {
            this.files = this.files.map(file => file.id === files[0].id ? {
              ...files[0],
              file_source: res.data[0].file_source,
              loading: false
            } : file);
          }
        });
    },
    duplicateFile(fileId) {
      this.updateFilesHistory();
      this.$api.moodBoard.duplicateFile(this.$route.params.project_id, this.moodBoardData.id, fileId)
        .then(res => {
          const file = this.transformFileFromBackend(res.data);
          file.position.z_index = this.maxFileIndex + 1;
          this.files.push(file);
        })
        .catch(err => {
          console.error(err);
        });
    },
    deleteFile(file) {
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want to delete this file?',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: () => {
            this.files = this.files.filter(item => item.id !== file.id);

            this.$store.dispatch('closeModal', 'confirmModal');
            this.$api.moodBoard.deleteFile(this.$route.params.project_id, this.moodBoardData.id, file.id);

            const newFilesHistory = this.filesHistory.steps.filter(item => !item.includes(file.id));
            this.filesHistory.index = this.filesHistory.index - (this.filesHistory.steps.length - newFilesHistory.length);
            this.filesHistory.steps = newFilesHistory;
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        }
      });
    },
    backStep() {
      if (this.filesHistory.index === this.filesHistory.steps.length) {
        this.filesHistory.defaultStep = JSON.stringify(this.files);
      }

      this.filesHistory.index -= 1;
      const historyFiles = JSON.parse(this.filesHistory.steps[this.filesHistory.index]);

      if (this.files.length === historyFiles.length) {
        this.updateFiles(historyFiles);
      } else {
        const filesId = historyFiles.map(item => item.id);
        const deleteItem = this.files.find(item => !filesId.includes(item.id));
        this.$api.moodBoard.deleteFile(this.$route.params.project_id, this.moodBoardData.id, deleteItem.id);
        this.filesHistory.steps = this.filesHistory.steps.slice(0, this.filesHistory.index);
      }

      this.files = [...historyFiles];
    },
    forwardStep() {
      this.filesHistory.index += 1;
      let historyFiles;

      if (this.filesHistory.index === this.filesHistory.steps.length) {
        historyFiles = JSON.parse(this.filesHistory.defaultStep);
      } else {
        historyFiles = JSON.parse(this.filesHistory.steps[this.filesHistory.index]);
      }

      if (this.files.length === historyFiles.length) {
        this.updateFiles(historyFiles);
      }

      this.files = [...historyFiles];
    },
    updateFilesHistory() {
      if (this.moodBoardData.disabled || !this.getPermission(this.$route.params.project_id).moodboard['can-edit-moodboard']) {
        return;
      }

      if (this.filesHistory.index < this.filesHistory.steps.length) {
        this.filesHistory.steps = this.filesHistory.steps.slice(0, this.filesHistory.index);
      }
      this.filesHistory.steps.push(JSON.stringify(this.files));
      if (this.filesHistory.steps.length > 10) {
        this.filesHistory.steps.shift();
      }
      this.filesHistory.index = this.filesHistory.steps.length
    },
    transformMoodboardFromBackend(board) {
      return {
        ...board,
        menu: false,
        disabled: board.status === this.$config.project.moduleStatus.locked,
        loading: false,
        newName: board.name
      }
    },
    transformFileFromBackend(file) {
      const moodBoard = this.$refs.mood_board;
      const width = parseInt(file.position.width * moodBoard.clientWidth / 100, 10); // convert from % to px
      const height = parseInt(file.position.height * this.moodBoardDefaultHeight / 100, 10); // convert from % to px
      const x = parseInt(file.position.x * (moodBoard.clientWidth - width) / 100, 10); // convert from % to px
      const y = parseInt(file.position.y * this.moodBoardDefaultHeight / 100, 10); // convert from % to px
      return {
        ...file,
        position: {
          ...file.position, x, y, width, height,
          z_index: parseInt(file.position['z-index'], 10),
        },
        loading: false,
        active: false,
        showVideo: false,
      }
    },
    buildGrid() {
      this.updateFilesHistory();
      this.gridLoading = true;

      this.$nextTick(() => {
        this.gridLoading = false;
        this.$nextTick(() => {
          const grid = document.querySelector('.vue-flex-waterfall');
          const gridElements = grid.querySelectorAll('.waterfall-item');

          if (grid.clientHeight > this.$refs.mood_board_files.clientHeight) {
            this.$refs.mood_board_files.style.height = `${grid.clientHeight}px`;
          }

          gridElements.forEach((item, idx) => {
            this.files[idx].position.x = item.offsetLeft;
            this.files[idx].position.y = item.offsetTop;
            this.files[idx].position.width = item.clientWidth;
          });

          this.filesLoading = true;

          this.$nextTick(() => {
            this.updateFiles(this.files);
            this.filesLoading = false;
          });
        });
      });
    },
    async goToProjectDesign() {
      const moodBoardModule = this.getProject.modules.find(module => module.type === this.$config.project.moduleTypes.moodBoard);

      if (moodBoardModule.status === this.$config.project.moduleStatus.skipped) {
        await this.$router.push({name: 'ProjectDesign', params: {project_id: this.getProject.id}});
        return;
      }

      try {
        await this.$api.moodBoard.skip(this.$route.params.project_id);
        await this.$router.push({name: 'ProjectDesign', params: {project_id: this.getProject.id}});
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-header {
  border-bottom: none;
  &::before {
    content: '';
    position: absolute;
    bottom: 62px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--v-gray-30-base);
  }
}

.mood-board-page-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mood-board {
  z-index: 2;
  background: transparent url("~@/assets/images/light/background/bg-grid-view.png") repeat;
}

::v-deep .mood-board-files-wrap {
  position: relative;
  height: calc(100vh - 65px - 207px);
  // 65px - app menu height
  // 207px bottom logo height

  .mood-board-file {
    border: 2px solid var(--v-gray-0-base);
    background-color: var(--v-gray-10-base);

    .mood-board-file-content {
      display: flex;
    }

    &:hover,
    &.active {
      .mood-board-file-menu {
        opacity: 1;
      }
    }

    &.active {
      border-color: var(--v-accent-base) !important;
    }
  }

  .mood-board-file-menu {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    z-index: 2;
    opacity: .1;
  }

  .file-drag-icon {
    width: 24px;
    height: 24px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .mood-board-video-preview {
    .mood-board-load-video-btn.v-btn.v-btn--has-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(#212121, .8);

      &:hover {
        background-color: #f00;
      }
    }
  }
}

.vue-flex-waterfall {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.waterfall-item {
  //background: #000;
}

</style>
