<template>
  <UiMenu
    bottom
    right
    offset-y
    :nudge-top="-6"
    min-width="156"
    :z-index="file.position.z_index + 1"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn
        v-bind="attrs"
        v-on="on"
        fab
        width="24"
        height="24"
        color="gray-30"
      >
        <IconDotsH width="16" class="gray-60--text"/>
      </UiBtn>
    </template>

    <v-list dense nav color="gray-10" class="pa-2">
      <v-list-item class="px-2" @click="duplicateFile">
        <v-list-item-title class="text-captions-1">
          <IconCopy width="14" class="mr-2 gray-60--text"/>
          Duplicate
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="px-2" @click="editVideoUrl" v-if="file.file_type === 1">
        <v-list-item-title class="text-captions-1">
          <IconPen width="16" class="mr-2 gray-60--text"/>
          Edit url
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="px-2" @click="deleteFile">
        <v-list-item-title class="text-captions-1">
          <IconDelete width="16" class="mr-2 gray-60--text"/>
          Delete
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FileMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconCopy: () => import('@/components/icons/IconCopy'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    UiMenu,
    UiBtn,
  },
  props: {
    file: {
      type: Object,
      default: null,
    }
  },
  methods: {
    deleteFile() {
      this.$emit('deleteFile', this.file);
    },
    duplicateFile() {
      this.$emit('duplicateFile', this.file.id);
    },
    editVideoUrl() {
      this.$store.dispatch('openModal', {
        modalName: 'moodBoardVideoUrlModal',
        data: {
          file: this.file,
        },
      });
    }
  }
}
</script>
