<template>
  <div v-if="moodBoardData" class="mood-board-filters pt-9">

    <div class="d-flex flex-column flex-sm-row align-center justify-space-between mb-9">
      <div class="d-flex align-center flex-column flex-md-row overflow-hidden" style="max-width: 100%; padding: 0 10px;">
        <draggable
          v-model="draggableListMenu"
          v-bind="dragOptions"
          ghost-class="ghost"
          class="mood-board-projects d-flex overflow-x-auto px-3 mx-n3"
          @change="changeOrder">
          <v-scroll-x-transition class="d-flex" group tag="div">
            <div v-for="(moodboard, idx) in draggableListMenu" :key="idx" :class="{'draggable': !moodboard.menu}">
              <UiMenu
                v-model="moodboard.menu"
                allow-overflow
                min-width="170"
                right
                :nudge-bottom="6"
                offset-y
                z-index="5"
              >
                <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                  <v-tooltip top color="accent" z-index="5">
                    <template v-slot:activator="{ on, attrs }">
                      <UiBtn
                        v-on="on"
                        v-bind="attrs"
                        exact
                        text
                        width="170"
                        color="gray-60"
                        :ripple="false"
                        :loading="moodboard.loading"
                        :class="{'v-btn--active': +activeMoodboard === moodboard.id}"
                        class="mood-board-project text-none my-2 px-3"
                        @click="toMoodboard(moodboard.id)"
                      >
                        <IconMoodboard width="16" class="mr-2"/>
                        <span class="text-truncate mr-auto">{{ moodboard.name }}</span>

                        <v-icon v-if="moodboard.disabled" class="flex-shrink-0 ml-1" color="accent" right size="10">fa-lock</v-icon>

                        <v-hover v-slot="{ hover }">
                          <div v-bind="attrsMenu" v-on="onMenu" @click.prevent>
                            <IconDotsV
                              width="16"
                              class="flex-shrink-0 ml-1 handledrag"
                              :color="moodboard.menu || hover ? 'accent': ''"
                              style="margin-top: 3px;"
                            />
                          </div>
                        </v-hover>
                      </UiBtn>
                    </template>
                    <span>{{ moodboard.name }}</span>
                  </v-tooltip>
                </template>

                <v-list nav dense color="gray-10" class="py-2 px-0">
                  <v-list-item
                    :disabled="!getPermission($route.params.project_id).moodboard['can-rename-moodboard'] || moodboard.disabled"
                    class="px-5"
                    @click="openRenameModal(moodboard)"
                  >
                    <v-list-item-title class="text-captions-1">
                      <IconPen width="16" class="mr-2 gray-60--text"/>
                      Rename Board
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!getPermission($route.params.project_id).moodboard['can-lock-moodboard'] && !getPermission($route.params.project_id).moodboard['can-unlock-moodboard']"
                    class="px-5"
                    @click="lockUnlockMoodboard(moodboard)"
                  >
                    <v-list-item-title class="text-captions-1 pl-6">
                      {{moodboard.status === $config.project.moduleStatus.locked ? 'Unlock' : 'Lock'}} Board
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!getPermission($route.params.project_id).moodboard['can-delete-moodboard'] || moodboard.is_default"
                    class="px-5"
                    @click="removeMoodBoard(moodboard)"
                  >
                    <v-list-item-title class="text-captions-1">
                      <IconDelete width="16" class="mr-2 gray-60--text"/>
                      Remove Board
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!getPermission($route.params.project_id).moodboard['can-edit-moodboard'] || moodboard.disabled"
                    class="px-5"
                    @click="addUrl(moodboard)"
                  >
                    <v-list-item-title class="text-captions-1">
                      <IconGlobe width="16" class="mr-2 gray-60--text"/>
                      Add URL Link
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </UiMenu>
            </div>
          </v-scroll-x-transition>
        </draggable>
      </div>

      <div class="ml-md-4 d-flex align-center">
        <UiMenu
          left
          allow-overflow
          offset-y
          offset-x
          max-width="214"
          min-width="214"
          nudge-bottom="12"
          nudge-right="48"
          z-index="5"
        >
          <template v-slot:activator="{ on, attrs }">
            <UiBtn
              v-bind="attrs"
              v-on="on"
              fab
              width="30"
              height="30"
              color="accent"
            >
              <IconPlus width="18"/>
            </UiBtn>
          </template>

          <v-list nav dense color="gray-10" class="py-2 px-0">
            <v-list-item
              :disabled="moodBoardData.disabled || !getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
              class="px-0"
            >
              <v-list-item-title class="text-captions-1">
                <UIFilesUploader
                  :files-formats="$config.project.modulesFileFormats[$config.project.modules.moodBoard]"
                  :max-file-size="$config.filesystem.project.moodBoard.file.maxUploadFileSize"
                  :disabled="moodBoardData.disabled || !getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
                  multiple
                  class="d-flex align-center relative overflow-hidden cursor-pointer"
                  @onChange="files => this.$emit('uploadFiles', files)"
                  style="padding: 7px 20px;"
                >
                  <IconUpload width="16" class="mr-2 gray-60--text"/>
                  Upload Whiteboard Files
                </UIFilesUploader>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!getPermission($route.params.project_id).moodboard['can-delete-moodboard'] || moodBoardData.is_default"
              class="px-5"
              @click="removeMoodBoard(moodBoardData)"
            >
              <v-list-item-title class="text-captions-1">
                <IconDelete width="16" class="mr-2 gray-60--text"/>
                Delete Whiteboard
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="moodBoardData.disabled || !getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
              class="px-5"
              @click="$store.dispatch('openModal', 'moodBoardVideoUrlModal')"
            >
              <v-list-item-title class="text-captions-1">
                <IconGlobe width="16" class="mr-2 gray-60--text"/>
                Add URL link
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!getPermission($route.params.project_id).moodboard['can-create-moodboard']"
              class="px-5"
              @click="createMoodboard"
            >
              <v-list-item-title class="text-captions-1">
                <IconAddNewItem width="16" class="mr-2 gray-60--text"/>
                New Whiteboard
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </UiMenu>
        <!--      <v-tooltip
                v-if="getPermission($route.params.project_id).moodboard['can-complete-moodboard']"
                left
                color="background_10"
                content-class="mood-board-approve-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <UiBtn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    width="32"
                    height="32"
                    color="done"
                    @click="completeMoodBoard"
                    :disabled="moodBoardData.disabled"
                  >
                    <v-icon size="14" color="white">fa-check</v-icon>
                  </UiBtn>
                </template>
                <span class="text_color_4--text">Complete Whiteboard</span>
              </v-tooltip>-->
      </div>
    </div>

    <div class="d-flex align-center justify-end">
      <v-btn
        :class="['mr-1', {'is-disabled': !files.length ? true : filesHistory.index <= 0}]"
        :disabled="moodBoardData.disabled || !files.length ? true: filesHistory.index <= 0"
        elevation="0"
        fab
        height="32"
        icon
        width="32"
        @click="$emit('backStep')"
      >
        <IconArrowRotateLeft width="16" class="gray-60--text"/>
      </v-btn>
      <v-btn
        :class="['mr-2', {'is-disabled': !files.length ? true : filesHistory.steps.length === filesHistory.index}]"
        :disabled="moodBoardData.disabled || !files.length ? true: filesHistory.steps.length === filesHistory.index"
        elevation="0"
        fab
        height="32"
        icon
        width="32"
        @click="$emit('forwardStep')"
      >
        <IconArrowRotateRight width="16" class="gray-60--text"/>
      </v-btn>
      <v-btn
        :disabled="moodBoardData.disabled || !files.length || !getPermission($route.params.project_id).moodboard['can-edit-moodboard']"
        elevation="0"
        fab
        height="32"
        icon
        width="32"
        @click="$emit('buildGrid')"
      >
        <IconThumbnail width="16" class="gray-60--text"/>
      </v-btn>
    </div>

    <UITextModal
      v-if="getPermission($route.params.project_id).moodboard['can-rename-moodboard']"
      max-width="500"
      modal-name="renameMoodBoardModal"
      @hideModal="closeRenameModal"
    >
      <ValidationObserver
        ref="form"
        v-slot="{ handleSubmit }"
        class="pt-14 pb-8 px-6 px-sm-10"
        tag="div"
      >
        <v-form @submit.prevent="handleSubmit(submitHandler)">
          <ValidationProvider v-slot="{ errors }" class="d-block mb-sm-6" mode="eager" name="name" rules="required|max:128" tag="label" vid="name">
            <div class="input-label font-weight-medium text_color_18--text mb-2">Rename moodboard</div>
            <v-text-field
              v-model="renameBoard.newName"
              autofocus
              class="input rounded-0"
              dense
              hide-details
              outlined
              placeholder="Enter new name"
            />
            <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown" mode="out-in">
              <div v-if="errors.length" class="input-error text_color_5--text mt-1">
                <b>Error!</b> {{ errors [0] }}
              </div>
            </transition>
          </ValidationProvider>

          <div class="text-right">
            <v-btn
              class="save-template-btn font-weight-semi-bold rounded-0"
              color="primary"
              depressed
              height="36"
              type="submit"
              width="180"
            >
              Save
            </v-btn>
          </div>
        </v-form>
      </ValidationObserver>
    </UITextModal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Draggable from "vuedraggable";
import UITextModal from "@/components/UI/UITextModal";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'MoodBoardMenu',
  components: {
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconGlobe: () => import('@/components/icons/IconGlobe'),
    IconAddNewItem: () => import('@/components/icons/IconAddNewItem'),
    IconThumbnail: () => import('@/components/icons/IconThumbnail'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconMoodboard: () => import('@/components/icons/IconMoodboard'),
    IconDotsV: () => import('@/components/icons/IconDotsV'),
    IconArrowRotateLeft: () => import('@/components/icons/IconArrowRotateLeft'),
    IconArrowRotateRight: () => import('@/components/icons/IconArrowRotateRight'),

    Draggable,
    UITextModal,
    UIFilesUploader,
    UiMenu,
    UiBtn,
  },
  props: {
    creatingMoodboard: {
      type: Boolean,
      default: false
    },
    moodBoardList: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => []
    },
    activeMoodboard: {
      type: [Number, String],
      default: -1
    },
    moodBoardData: {
      type: Object,
      default: null,
    },
    filesHistory: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      renameBoard: {
        newName: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        draggable: ".draggable"
      };
    },
    draggableListMenu: {
      get() {
        return this.moodBoardList
      },
      set(val) {
        this.$emit('updateListMenu', val)
      }
    }
  },
  methods: {
    startMove(e) {
      console.log(e)
    },
    changeOrder(event) {
      this.$emit('sortBoard', {moodboard: event.moved.element, order: event.moved.newIndex+1})
    },

    lockUnlockMoodboard(moodboard) {
      this.$emit('lockUnlockBoard', moodboard)
    },

    submitHandler() {
      this.$emit('renameMoodboard', this.renameBoard)
      this.$store.dispatch('closeModal', 'renameMoodBoardModal');
      this.closeRenameModal();
    },

    openRenameModal(moodboard) {
      this.$store.dispatch('openModal', 'renameMoodBoardModal');
      this.renameBoard = moodboard;
    },

    closeRenameModal() {
      this.renameBoard = {newName: ''};
      this.$refs.form.reset()
    },
    toMoodboard(id) {
      this.$emit('changeMoodboard', id)
    },
    createMoodboard() {
      if(!this.getPermission(this.$route.params.project_id).moodboard['can-create-moodboard']) return
      this.$emit('createBoard')
    },

    removeMoodBoard(moodBoard) {
      if(!this.getPermission(this.$route.params.project_id).moodboard['can-delete-moodboard'] || moodBoard.is_default) return;
      this.$emit('removeMoodBoard', moodBoard);
    },
    addUrl(moodboard) {
      moodboard.menu= false
      this.toMoodboard(moodboard.id)
      this.$store.dispatch('openModal', 'moodBoardVideoUrlModal');
    },
    renameMoodboard(moodboard) {
      this.$emit('renameMoodboard', moodboard)
    },
    completeMoodBoard() {
      this.$api.moodBoard.complete(this.$route.params.project_id, this.moodBoardData.id)
        .then(res => {
          const firsDesignModule = res.data.modules.filter(module => module.type === this.$config.project.moduleTypes.design)[0];
          this.$store.dispatch('setProject', res.data);
          if (firsDesignModule) {
            this.$router.push({
              name: 'ProjectDesign',
              params: {project_id: res.data.id},
              query: {module: firsDesignModule.id}
            });
          }
        })
        .catch(err => {
          console.error(err);
        })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .mood-board-projects {
  max-width: calc(100% + 24px);

  .mood-board-project {
    border-bottom: 3px solid transparent;
    border-radius: 5px 5px 0 0 ;

    .v-btn__content {
      width: 100%;
    }

    &.v-btn--active {
      border-color: var(--v-accent-base);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background-color: var(--v-gray-0-base);

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1 !important;
        width: 40px;
        height: 2px;
        border-radius: 3px;
      }
    }

    &:before,
    &:after {
      opacity: 0 !important;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
}

.mood-board-approve-tooltip {
  opacity: 1 !important;
}

.is-disabled,
.v-list-item--disabled {
  opacity: .7;
}
</style>
